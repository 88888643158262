.detail-modal-close-btn {
  background-color: #333;
  color: #fff;
  padding: .8rem 2rem;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  font-size: 1.2rem;
  margin-top: 1rem;
  @media screen and (max-width: 450px) {
    padding: .5rem 1.5rem;
  }
}

.my-custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 3rem;
  background-color: #fff;
  width: 80%;
  padding: 2rem 3rem;
  height: calc(100% - 8rem);
  margin-top: 2rem;
  border: 2px solid #ccc;
  border-radius: 1px;
  overflow-y: auto;
  @media screen and (max-width: 450px) {
    padding: 1rem 1rem;
  }
}
